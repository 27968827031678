import React, { FC } from 'react';
import Link from 'next/link';
import { Skeleton } from 'components/tailwind/Skeleton';
import { useUsersQuery } from '../generated/graphql';

export const UserList: FC = () => {
  const { data, loading } = useUsersQuery({});

  return (
    <div className="mt-3 space-y-2">
      {loading
        ? ['a', 'b', 'c', 'd'].map((_, i) => {
            return (
              <Skeleton loading={true} key={i}>
                <div style={{ height: '36px' }}></div>
              </Skeleton>
            );
          })
        : data.users.map(user => (
            <Link
              href="/u/[userId]"
              as={`/u/${user.username}`}
              key={user.username}
              className="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
            >
              <span className="truncate">{user.username}</span>
            </Link>
          ))}
    </div>
  );
};

export default UserList;
