import React, { FC } from 'react';
import Link from 'next/link';
import { Button } from '@pixby/ui';
import { Layout } from '../components/layouts/Layout';
import { withUser } from '../hocs/with-user';
import { useUser } from '../context/user';
import { Typography } from '../components/tailwind/Typography';
import { useCreateTinkLinkMutation } from '../generated/graphql';

export const Index: FC = ({}) => {
  const { user } = useUser();
  const [createTinkLinkMutation] = useCreateTinkLinkMutation();
  const handleCreateTinkLink = async () => {
    try {
      const response = await createTinkLinkMutation({
        variables: {
          market: 'SE',
        },
      });
      if (response?.data?.createTinkLink) {
        window.location.href = response.data.createTinkLink;
      }
    } catch (err) {
      alert('error when creating tink link');
    }
  };
  return (
    <Layout>
      <div className="h-full flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8 pb-20">
        <div className="flex flex-col items-center max-w-md w-full space-y-2">
          <Typography variant="h1">Stockpicks</Typography>
          <Typography variant="body1">
            The social platform for stocks and Investing.
          </Typography>
          <div className="pt-2">
            {user ? (
              <>
                <Link
                  href="/u/[userId]"
                  as={`/u/${user.username}`}
                  passHref
                  legacyBehavior
                >
                  <Button colorScheme="primary" size="md">
                    Go to your profile
                  </Button>
                </Link>
              </>
            ) : (
              <Button
                colorScheme="primary"
                size="md"
                onClick={handleCreateTinkLink}
              >
                Create account
              </Button>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withUser({ requireUser: false })(Index);
