import React from 'react';
import Router from 'next/router';
import { useCurrentUserQuery } from '../generated/graphql';
import { UserContext } from '../context/user';
import { logger } from 'lib/logger';

interface Options {
  requireUser?: boolean;
  redirectIfUser?: string | false;
  redirectIfNoUser?: string | false;
}

const redirect = (location: string) => {
  Router.push(location);
};

export const withUser = (options: Options = {}) => Page => {
  // get the options, and use some sensible defaults
  const {
    requireUser = true,
    // redirectIfUser = false,
    redirectIfNoUser = '/login',
  } = options;

  const AuthPage = props => {
    // redirect if we require a user, don't have a jwt token and redirectIfNoUser is set
    if (typeof window !== 'undefined') {
      const jwt = localStorage.getItem('token');
      if (requireUser && !jwt && redirectIfNoUser) {
        redirect('/login');
        return <p>redirecting to login</p>;
      }
    }

    // if we have a token set, load the current user
    const { data, loading, error } = useCurrentUserQuery({
      onCompleted: data => {
        const user = data?.currentUser;
        logger.setUser({
          username: user?.username,
          email: user?.email,
          id: user?.id,
        });
      },
    });

    const user = data?.currentUser;

    // if the page requires a user but the query fails, show an error
    // TODO: Replace this with an Error component to make it prettier
    // See; https://github.com/filipstefansson/mockql/blob/master/components/error.tsx
    if (requireUser && error) {
      return (
        <pre>
          <code>{JSON.stringify(error, null, 2)}</code>
        </pre>
      );
    }

    // otherwise we will let the user continue to the page
    return (
      <UserContext.Provider
        value={{
          user,
          loading,
        }}
      >
        <Page {...props} />
      </UserContext.Provider>
    );
  };

  return AuthPage;
};
