import React, { FC } from 'react';
import { Home, Fire, UserGroup, TrendingUp, Info } from './icons';

export type IconName = 'Home' | 'Fire' | 'UserGroup' | 'TrendingUp' | 'Info';

interface Props {
  name: IconName;
}

const Icons = {
  Home: Home,
  Fire: Fire,
  UserGroup: UserGroup,
  TrendingUp: TrendingUp,
  Info: Info,
};

export const Icon: FC<Props> = ({ name }) => {
  const Component = Icons[name];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <Component />
    </svg>
  );
};
