import React, { FC } from 'react';
import { DesktopMenu } from './components/desktop-menu';
import { Header } from './components/Header';
import { SlideIn } from '../tailwind/SlideIn';
import { IconName } from '../tailwind/Icon';
import clsx from 'clsx';

export interface NavigationLink {
  label: string;
  href: string;
  icon: IconName;
}

const NAVIGATION_LINKS: NavigationLink[] = [
  {
    label: 'Home',
    href: '/',
    icon: 'Home',
  },
  {
    label: 'Popular',
    href: '/popular',
    icon: 'Fire',
  },
  {
    label: 'Users',
    href: '/users',
    icon: 'UserGroup',
  },
  // {
  //   label: 'Trending',
  //   href: '/#trending',
  //   icon: 'TrendingUp',
  // },
];

interface Props {
  drawerContent?: React.ReactNode;
  hasCenteredContent?: boolean;
}

export const Layout: FC<Props> = ({
  children,
  drawerContent,
  hasCenteredContent = false,
}) => {
  return (
    <div className="flex flex-col flex-auto bg-gray-100">
      <Header links={NAVIGATION_LINKS} />
      <div className="py-6 h-full">
        <div className="h-full mx-auto grid grid-cols-12 lg:gap-8 px-6">
          <div className="hidden lg:block lg:col-span-3 xl:col-span-2">
            <DesktopMenu links={NAVIGATION_LINKS} />
          </div>
          <main
            className={clsx('col-span-12 lg:col-span-9 xl:col-span-10', {
              'flex justify-center': hasCenteredContent,
            })}
          >
            {children}
          </main>
        </div>
      </div>
      {drawerContent && (
        <SlideIn isOpen={true} overlay={true}>
          {drawerContent}
        </SlideIn>
      )}
    </div>
  );
};
