import React, { FC } from 'react';
import clsx from 'clsx';

interface Props {
  loading: boolean;
  className?: string;
}

export const Skeleton: FC<Props> = ({ children, loading, className }) => {
  if (!loading) {
    return <>{children}</>;
  }

  return (
    <div className={clsx(['block bg-gray-300 animate-pulse', className])}>
      <div style={{ opacity: 0 }}>{children}</div>
    </div>
  );
};
