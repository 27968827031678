import React, { FC } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import Link from 'next/link';
import { Icon } from '../../tailwind/Icon';
import { NavigationLink } from '../Layout';
import { UserList } from '../../UserList';

interface Props {
  links: NavigationLink[];
}

export const DesktopMenu: FC<Props> = ({ links }) => {
  const router = useRouter();
  return (
    <nav aria-label="Sidebar" className="sticky top-4 divide-y divide-gray-300">
      <div className="pb-8 space-y-1">
        {links.map(link => (
          <Link
            href={link.href}
            passHref
            key={link.href}
            className={clsx(
              ' group flex items-center px-3 py-2 text-sm font-medium rounded-md',
              {
                'bg-gray-200 text-gray-900': router.pathname === link.href,
                'text-gray-600 hover:bg-gray-50': router.pathname !== link.href,
              },
            )}
            aria-current="page"
          >
            <div className="flex-shrink-0 -ml-1 mr-3 h-6 w-6 text-gray-500">
              <Icon name={link.icon} />
            </div>
            <span className="truncate">{link.label}</span>
          </Link>
        ))}
      </div>
      <div className="pt-10">
        <p className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider">
          Users
        </p>
        <UserList />
      </div>
    </nav>
  );
};
