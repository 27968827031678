import React, { FC } from 'react';
import { Transition } from '@headlessui/react';

const Overlay: FC<{ isOpen: boolean }> = ({ children, isOpen }) => {
  return (
    <div className="z-10 fixed inset-0 overflow-hidden">
      <div className="absolute inset-0 overflow-hidden">
        <Transition
          show={isOpen}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
        </Transition>
        {children}
      </div>
    </div>
  );
};

interface Props {
  isOpen: boolean;
  overlay: boolean;
}

export const SlideIn: FC<Props> = ({ children, isOpen, overlay }) => {
  const component = (
    <section
      className="absolute inset-y-0 right-0 pl-10 max-w-full flex"
      aria-labelledby="slide-over-heading"
    >
      <Transition
        show={isOpen}
        enter="transform transition ease-in-out duration-500 sm:duration-700"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transform transition ease-in-out duration-500 sm:duration-700"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        {children}
      </Transition>
    </section>
  );

  if (overlay) {
    return <Overlay isOpen={isOpen}>{component}</Overlay>;
  } else {
    return component;
  }
};
